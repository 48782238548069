import request from './request.js'
import { getItem } from '@/utils'
import store from '@/store/index.js'
import { baseUrl } from './base-url'

// import {getMemberId} from

request.setConfig({
	baseUrl,
	debug: true
})

// 请求拦截
request.interceptor.request = (config) => {
	if (config.isLoad === true) {
		uni.showLoading({
			title: '加载中'
		})
	}
	// console.log('请求拦截', config)
	if (!config.header.token) {
		config.header.token = getItem('token')
		// config.header.token = ''
	}
	config.header['X-Lm-Source'] = 'guide'
	// 添加一个自定义的参数，默认异常请求都弹出一个toast提示
	if (config.toastError === undefined) {
		config.toastError = true
	}
	return config
}

/* 
	处理状态码code值说明：
	0    -- 操作成功
	200  -- 操作成功
	401  -- token过期
*/

// 全局的业务拦截
let isRefreshing = false // 是否在请求获取token
let requests = [] // 请求队列
request.interceptor.response = (res, config, resolve, reject) => {
	console.log('全局业务拦截', res, config)
	if (config.isLoad === true) {
		uni.hideLoading()
	}
	if (!config.closeIntercept) {
		request.cancelTokenResponse(config)
	}
	if (res.code == 0 || [200, 42025, 40001, 42029, 40404, 40405, 40406].includes(res.code)) {
		res.success = true
		config.businessSuccess = true
	} else if (res.code == '401') {
		/* 
			用户登录信息过期
		*/
		config.header.token = ''
		store.commit('user/RESET_STATE')

		// #ifdef MP-WEIXIN
		requests.push({
			resolve,
			selfConfig: config,
			fn: (selfConfig) => request.request(selfConfig)
		})
		// #endif
		if (!isRefreshing) {
			isRefreshing = true
			store
				.dispatch('user/GET_TOKEN')
				.then(() => {
					// #ifdef MP-WEIXIN
					requests.forEach((item) => {
						console.log('请求----', item.selfConfig)
						if (item.selfConfig.url.indexOf('task/work_task_list' !== -1)) {
							item.selfConfig.data.member_userid = getItem('user_info').qw_userid
						}
						item.fn(item.selfConfig).then((res) => item.resolve(res))
					})
					requests = []
					// #endif
				})
				.catch((err) => {
					console.log('获取token失败', err)
				})
				.finally(() => {
					isRefreshing = false
				})
		}
		return
	}
	return res
}

// 全局的错误异常处理
request.interceptor.fail = (res, config) => {
	console.log('错误了!!!!!!!!!!!!!', res, config)
	let ret = res
	let msg = ''
	if (res.statusCode === 200) {
		// 业务错误
		msg = res.data.msg
		ret = res.data
	} else if (res.statusCode > 0) {
		// HTTP错误
		msg = '服务器异常[' + res.statusCode + ']'
	} else {
		// 其它错误
		console.log('res', res)
		msg = res.errMsg
		if (msg.indexOf('request:fail') != -1) {
			msg = '请检查网络，再次尝试~'
		}
	}

	if (config.toastError && msg) {
		setTimeout(() => {
			uni.showToast({
				title: msg,
				duration: 2000,
				icon: 'none'
			})
		}, 300)
	}

	return ret
}

export default request
